import React from 'react'

import Router from './routes'
import { BrowserRouter } from 'react-router-dom'

import './index.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <BrowserRouter basename='/'>
        <ToastContainer 
            autoClose={1000} 
            closeButton={false}
            theme='colored'
            className='h-5'
        />
      <Router />
  </BrowserRouter>
  );
}

export default App;

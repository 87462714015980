import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Loading = (props) => {
    if(props.type == 'small'){
        return (
            <div className="flex w-full h-full items-center justify-center">
                <FontAwesomeIcon icon={faSpinner} spin size="3x"></FontAwesomeIcon>
            </div>
            )
    }else{
        return (
            <div className="flex w-screen h-screen items-center justify-center">
                <FontAwesomeIcon icon={faSpinner} spin size="3x"></FontAwesomeIcon>
            </div>
            )
    }
    
}

export default Loading
//react package
import { lazy,Suspense } from "react";
import { useRoutes,Navigate, useOutletContext } from "react-router";
import { Outlet, Link } from 'react-router-dom'

//loader & layout
import Loading from './Components/Loading'
import BasicLayout from './Layouts/BasicLayout'
// import RegistrationLayout from "./Layouts/RegistrationLayout";

//pages
const Login = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Login'))
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Home'))
const Detail = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Detail'))
const Report = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/Report'))
const NotFound = lazy(() => import(/* webpackChunkName: "Home" */ './Pages/NotFound'))

const RouteList = ()=>{
    return useRoutes([
        {
            path:"/",
            element: <BasicLayout></BasicLayout>,
            children:[
                {
                    path:'/',
                    element: <Home></Home>
                },
                {
                    path:'/report',
                    element: <Report></Report>
                }, 
                {
                    path:'/detail/:id',
                    element: <Detail></Detail>
                },                
                {
                    path:'*',
                    element:<Navigate to={"/404"}></Navigate>
                }
            ]
        },
        {
            path:"/login",
            element: 
            <Suspense fallback={<Loading text="Loading page ..." />}>
                <Login></Login>
            </Suspense>
            ,
        },     
        {
            path:"/404",
            element: (
                <Suspense fallback={<Loading text="Loading page ..." />}>
                  <NotFound />
                </Suspense>
              )
        }
    ])
}
export default RouteList
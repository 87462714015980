import axios from "axios";
import toast from "./Utility/toast"

import {store} from "../Redux/store";
import { createBrowserRouter } from "react-router-dom";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers:{
        // Authorization:'Bearer '+JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).access_token
    }
})
if(localStorage.getItem("persist:user") != undefined && JSON.parse(JSON.parse(localStorage.getItem("persist:user"))?.value)?.access_token){
    instance.defaults.headers.common['Authorization'] = 'Bearer '+JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).access_token
}

instance.interceptors.response.use(res=>{
//for 200
    return res;
},
res=>{
//for 400-500
// console.log(JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).access_token)
    if(res.response.status == 401){
        window.location.href = process.env.REACT_APP_MAIN_URL+ '/login'
        localStorage.removeItem('persist:user')
        toast('Unauthorized')
    }else{
        toast(res.response.data.message)
        // console.log(res)
    }
    // throw res;
    // console.log(res)
})


export default instance


import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {
    },
  },
  reducers: {
    setData: (state,action) => {
      state.value = action.payload
      // let promise = new Promise(function(resolve,reject){
      //   resolve('a')
      // })
      // return promise
    }
  },
})

// Action creators are generated for each case reducer function
export const { setData } = userSlice.actions

export default userSlice.reducer

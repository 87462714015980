
//redux
import { setData } from '../../Redux/Slice/user'
import { useSelector, useDispatch } from 'react-redux'

import axios from "../index";

// const router = createBrowserRouter({ window });

export default function logout(){
    let promise = new Promise(function(resolve,reject){
        axios.post('/auth/logout',{
        }).then(res =>{
            if(res){
                delete axios.defaults.headers.common["Authorization"];
                // window.location.href = 
            }
            resolve(res)
        }).catch(err =>{
            reject(err)
        })
    })
    

    return promise
}